@font-face {
font-family: 'inter';
src: url(/reno/_next/static/media/f40d63d7760e63dd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/reno/_next/static/media/87bddd47aad0bd26-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'inter';
src: url(/reno/_next/static/media/d0e3dc8d5ad55483-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'inter Fallback';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_83f6bc {font-family: 'inter', 'inter Fallback'
}.__variable_83f6bc {--font-inter: 'inter', 'inter Fallback'
}

@font-face {
font-family: 'walsheim';
src: url(/reno/_next/static/media/6e76a948bfad5133-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'walsheim';
src: url(/reno/_next/static/media/56732510478c37c6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'walsheim Fallback';src: local("Arial");ascent-override: 82.46%;descent-override: 22.45%;line-gap-override: 0.00%;size-adjust: 109.14%
}.__className_884fa8 {font-family: 'walsheim', 'walsheim Fallback'
}.__variable_884fa8 {--font-walsheim: 'walsheim', 'walsheim Fallback'
}

